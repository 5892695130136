// import Vue from 'vue';
import {
    plainAxiosInstance
} from '../../axios/index';

const state = {
  
    auth_user: JSON.parse(localStorage.getItem("tag-us")),
    loading: false,
    credentials : {} ,
    addValues : {}
}


const getters = {
    loading: state => state.loading,
    credentials: state => state.credentials,
    auth_user: state => state.auth_user,
    addValues : state => state.addValues
   
}

const actions = {

    async login({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
           
            plainAxiosInstance
                .post("login", state.credentials)
                .then(response => {
                    console.log(response.data)
                    commit('setLoading', false)
                    if (response.data.status == "success") {
                        
                        resolve(response)
                      
                    } else {

                    }
                })
                .catch(error => {
                    reject(error)
                    dispatch('Alert/errorMsg', '', {
                        root: true
                    })
                })

        })
    },

    async register({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
           
            plainAxiosInstance
                .post("login", state.credentials)
                .then(response => {
                    console.log(response.data)
                    commit('setLoading', false)
                    if (response.data.status == "success") {
                        resolve(response)
                      
                    } else {

                    }
                })
                .catch(error => {
                    reject(error)
                    dispatch('Alert/errorMsg', '', {
                        root: true
                    })
                })

        })
    },



    setLoading({
        commit
    }, loading) {
        commit('setLoading', loading)
    },

}
const mutations = {
   
    setLoading(state, loading){
        state.loading = loading
    },



}

export default {

    namespaced: true,
    state,
    getters,
    actions,
    mutations

}