<template>
<section class="cards-component">
    <div class="types">
        <div class="type" v-for="item in lists.auction_types" :key="item.id" @click="filterByCat(item.id)">
            <div class="icon">
                <img :src="item.icon" alt="" />
            </div>
            <div class="text">{{ item.name }}</div>
        </div>
    </div>

    <div class="container">
        <div class="search-bar">
            <div class="right">
                <div class="input-con">
                    <FormulateInput class="form-input" name="المنطقة" label="المنطقة" @change="selectRegion($event.target.value)" :options="formatOptions(lists.regions)" type="select" />
                    <div class="img-con">
                        <img src="@/assets/icons/search.png" alt="" />
                    </div>
                </div>
                <div class="input-con">
                    <FormulateInput class="form-input" name="المدينة" label="المدينة" @change="selectCity($event.target.value)" :options="formatOptions(lists.cities)" type="select" />
                    <div class="img-con">
                        <img src="@/assets/icons/search.png" alt="" />
                    </div>
                </div>
                <!-- <div class="bell-con">
                    <img src="@/assets/icons/bell.png" alt="" />
                </div> -->
            </div>
            <div class="left">
                <ul>
                    <li @click="filterByStuts(1)">
                        <router-link to="/">قادم |</router-link>
                    </li>
                    <li @click="filterByStuts(2)">
                        <router-link to="/">جاري |</router-link>
                    </li>
                    <li @click="filterByStuts(3)">
                        <router-link to="/">منتهي </router-link>
                    </li>

                   

                </ul>
            </div>
        </div>
        <div class="cards-loading" v-if="!lists.auctions.length > 0 && loading">
            <div class="row">
                <div class="col-4" v-for="n in 9" :key="n">
                    <vue-skeleton-loader type="rect" width="100%" height="300px" animation="fade" class="loading-element" rounded />
                </div>
            </div>
        </div>

        <div class="cards">
            <div class="row">
                <div class="col-4" v-for="(item, index) in lists.auctions" :key="index">
                    <router-link :to="getRoute(item.id)">
                        <div class="card">
                            <div class="card-img">
                                <img :src="item.card_img" alt="" />
                                <div class="card-top-con">
                                    <div class="card-top">
                                        <div class="right">{{ item.title }}</div>
                                        <div class="left" v-if="item.city != null">
                                            <div class="icon">
                                                <img src="@/assets/icons/location.png" alt="" />
                                            </div>
                                            <div class="text">{{ item.city.name }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="time-left" v-if="item.statusByDate == 1">
                                    يبدا بعد
                                </div>
                                <div class="time-left" v-if="item.statusByDate == 2">
                                    ينتهي بعد
                                </div>
                                <div class="time-left" v-if="item.statusByDate == 3">
                                    إنتهاء المزاد
                                </div>
                            </div>
                            <div class="card-text">
                                <div class="card-text-heading">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="name-status">
                                                <div class="name">{{ item.typeBy }}</div>
                                                <div class="status">
                                                    {{ getStatus(item.statusByDate) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="mz_cards_side_time_left" v-if="info.startCountDown">
                                                <div class="row">
                                                    <!-- <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div class="mz_time_left_text">يبدأ بعد</div>
                                      </div> -->

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="mz_time_left_items">
                                                            <div class="mz_time_left_item mz_time_left_second">
                                                                <div class="mz_time_left_item_value" :id="`${item.id}-seconds`">
                                                                    0

                                                                </div>
                                                                <div class="mz_time_left_item_dots">:</div>
                                                                <div class="mz_time_left_item_text">
                                                                    ثانية
                                                                </div>
                                                            </div>
                                                            <div class="mz_time_left_item mz_time_left_minute">
                                                                <div class="mz_time_left_item_value" :id="`${item.id}-minutes`">0</div>
                                                                <div class="mz_time_left_item_dots">:</div>
                                                                <div class="mz_time_left_item_text">
                                                                    دقيقة
                                                                </div>
                                                            </div>
                                                            <div class="mz_time_left_item mz_time_left_hour">
                                                                <div class="mz_time_left_item_value" :id="`${item.id}-hours`">0</div>
                                                                <div class="mz_time_left_item_dots">:</div>
                                                                <div class="mz_time_left_item_text">ساعة</div>
                                                            </div>
                                                            <div class="mz_time_left_item mz_time_left_day">
                                                                <div class="mz_time_left_item_value" :id="`${item.id}-days`">0</div>
                                                                <div class="mz_time_left_item_text">يوم</div>
                                                                <!-- <div class="mz_time_left_item_dots">
                                                      :
                                                  </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="card-body-right">
                                                <div class="card-body-item b-b">
                                                    <div class="icon">
                                                        <unicon class="unicon" name="box" fill="#838487" width="23" height="23"></unicon>
                                                    </div>
                                                    <div class="text">
                                                        عدد المنتجات {{ item.productCount }}
                                                    </div>
                                                </div>
                                                <div class="card-body-item">
                                                    <div class="icon">
                                                        <img src="@/assets/icons/alarm.png" alt="" />
                                                    </div>
                                                    <div class="text">
                                                        من {{ formatDate(item.start_at) }}
                                                        <br />
                                                        إلى{{ formatDate(item.end_at) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card-body-left">
                                                <div class="icon">
                                                    <img src="@/assets/icons/calendar.png" alt="" />
                                                </div>
                                                <div class="text">
                                                    <div class="">
                                                        أيام المزاد
                                                        <span>{{
                                getDiffInDays(item.start_at, item.end_at)
                              }}</span>
                                                        أيام
                                                    </div>

                                                    <div class="">
                                                        من
                                                        <span> {{ item.dayName }} </span>
                                                        {{ item.startTime }}
                                                        <br />
                                                        الى
                                                        <span> {{ item.endDayName }} </span>
                                                        {{ item.endTime }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div class="right">
                                        <img src="@/assets/icons/heart.png" alt="" />
                                        <img src="@/assets/icons/share.png" alt="" />
                                    </div>
                                    <div class="left">
                                        {{ item.user.company_name }}
                                        <img src="@/assets/icons/right.png" alt="" class="arrow" />

                                        <div class="logo"></div>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="pagination-con">
            <div class="pagination">
                <div class="pagination-arrow">
                    <img @click="setAuctions(paginationData.currentPage - 1)" src="@/assets/icons/arrow.png" alt="" />
                </div>
                <div class="pagination-btns">
                    <button v-for="p in paginationData.lastPage" :key="p" :class="{ active: p == paginationData.currentPage }" @click="setAuctions(p)">
                        {{ p }}
                    </button>
                </div>
                <div class="pagination-arrow arrow-left">
                    <img @click="setAuctions(paginationData.currentPage + 1)" src="@/assets/icons/arrow.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    data() {
        return {
            startCountDown: false,
            timer: "",
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0,
            intervals: [],
            addedIntervals: [],
        };
    },
    computed: {
        ...mapGetters("Auctions", ["info", "lists", "paginationData", "loading"]),
    },

    beforeDestroy() {
        // destroyed  in Vue 2
        for (var i of this.intervals) {
            clearInterval(i);
        }
        // alert("The component is removed (unmounted)!");
        // console.log("unnnn");

    },

    methods: {
        ...mapActions("Auctions", [
            "setAuctions",
            "setAuctionTypes",
            "setRegions",
            "setCities",
            "resetAuctions"
        ]),

        handleCount(item, index) {
            var itemId = item.id;
            if (item.statusByDate == 1) {
                var start_at = item.start_at;
            } else start_at = item.end_at;

            // Set the date we're counting down to
            if (start_at && itemId) {
                var countDownDate = new Date(
                    Date.parse(start_at.replace(/-/g, "/"))
                ).getTime();

                // Update the count down every 1 second
                if (!this.addedIntervals.includes(itemId)) {
                    var x = setInterval(() => {
                        // Get today's date and time
                        var now = new Date().getTime();

                        // Find the distance between now and the count down date
                        var distance = countDownDate - now;

                        // Time calculations for days, hours, minutes and seconds
                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor(
                            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                        );
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                        if (distance > 0) {
                            if (document.getElementById(`${itemId}-seconds`) != null) document.getElementById(`${itemId}-seconds`).innerHTML = seconds;
                            if (document.getElementById(`${itemId}-minutes`) != null) document.getElementById(`${itemId}-minutes`).innerHTML = minutes;
                            if (document.getElementById(`${itemId}-hours`) != null) document.getElementById(`${itemId}-hours`).innerHTML = hours;
                            if (document.getElementById(`${itemId}-days`) != null) document.getElementById(`${itemId}-days`).innerHTML = days;
                        } else {
                            if (document.getElementById(`${itemId}-seconds`) != null) document.getElementById(`${itemId}-seconds`).innerHTML = 0;
                            if (document.getElementById(`${itemId}-minutes`) != null) document.getElementById(`${itemId}-minutes`).innerHTML = 0;
                            if (document.getElementById(`${itemId}-hours`) != null) document.getElementById(`${itemId}-hours`).innerHTML = 0;
                            if (document.getElementById(`${itemId}-days`) != null) document.getElementById(`${itemId}-days`).innerHTML = 0;
                        }

                        // If the count down is finished, write some text
                        if (distance < 0) {
                            clearInterval(x);
                            //   document.getElementById("demo").innerHTML = "EXPIRED";
                        }
                    }, 1000);

                    this.intervals.push(x);
                    this.addedIntervals.push(itemId)

                }

            }
        },

        getDiffInDays(s_date, e_date) {
            var st_date = new Date(s_date);
            var ed_date = new Date(e_date);
            var difference = ed_date.getTime() - st_date.getTime();

            var seconds = Math.floor(difference / 1000);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            var days = Math.floor(hours / 24);

            return days;
        },

        formatOptions(list) {
            var options = [];
            for (var item of list) {
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return options;
        },

        getStatus(status) {
            switch (status) {
                case 1:
                    return "قادم";
                    break;
                case 2:
                    return "جاري";
                    break;
                case 3:
                    return "منتهي";
                    break;

            }
        },

        async excuteClearInterval() {
            for (var i of this.intervals) {
                clearInterval(i);
            }
        },

        startTimer() {
            for (let index = 0; index < this.lists.auctions.length; index++) {
                this.handleCount(this.lists.auctions[index], index)

            }
        },

        async selectRegion(val) {
            this.info.region_id = val;
            this.setAuctions();
            this.setCities(val);
        },

        async selectCity(val) {
            this.info.city_id = val;
            this.setAuctions();
        },

        getRoute(id) {
            return "/auctions/" + id;
        },

        async filterByCat(id) {
            await this.resetAuctions()
            this.info.type_id = id;
            this.setAuctions().then(() => {
                this.startTimer()
            });;
        },

        async filterByStuts(status) {
            await this.resetAuctions()
            this.info.status = status;
            this.setAuctions().then(() => {
                this.startTimer()
            });;
        },

        showAll() {
            this.info = {};
            this.setAuctions().then(() => {
                this.startTimer()
            });;
        },

        formatDate(date) {
            var d1 = new Date(date);
            var ms = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
            return (
                d1.getDate() + "-" + ms[d1.getMonth()] + "-" + d1.getFullYear()
            );
        },

    },

    async mounted() {
        await this.setAuctions();

        this.setAuctionTypes().then(() => {
            this.startTimer()
        });

        this.setRegions();
        // this.handleCount();
    },
};
</script>

<style lang="scss" scoped>
.cards-component {
    background-color: #f8fafc;

    .col-4 {
        padding: 10px;
    }
}

.form-input {
    width: 100%;
}

.types {
    display: flex;

    .type {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #838487;
        background-color: #e9e9e9;
        border-left: 1px solid #838487;
        border-bottom: 1px solid #838487;
        padding: 10px;
        cursor: pointer;

        &:hover {
            border-bottom-color: $primary;
            color: $primary;
        }

        img {
            width: 70px;
            padding: 5px;
        }

        .sofa {
            img {
                width: 50px;
            }
        }
    }
}

a {
    &:hover {
        text-decoration: none;
    }
}

.search-bar {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .right {
        display: flex;
        align-items: center;

        .input-con {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .form-input {
                border: none;
                outline: none;

                width: 200px;

                height: 100%;
                border-radius: 0 5px 5px 0;
            }

            .img-con {
                background-color: $primary;
                padding: 8px;
                border-radius: 5px 0px 0px 5px;
                margin-top: 9px;

                img {
                    width: 20px;
                }
            }
        }

        .bell-con {
            background-color: #e9e9e9;
            padding: 8px;
            border-radius: 5px;
            height: 50px;
            margin-top: 15px;

            img {
                width: 20px;
            }
        }
    }

    .left {
        color: #f8691b;

        ul {
            list-style: none;
            padding: 0;
            display: flex;

            a {
                color: black;
                margin-right: 8px;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.card {
    //border-radius: 18px;
    //overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    margin-bottom: 20px;

    &:hover {
        border: 1px solid #000;

        .text-heading {
            text-decoration: underline;
        }
    }

    .card-img {
        height: 220px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .time-left {
            position: absolute;
            bottom: 0;
            left: 30px;
            padding: 5px 10px 0px;
            border-radius: 5px 5px 0 0;
            font-size: 12px;
            background-color: #b5b5b5;
            color: white;
        }

        .card-top-con {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            margin-top: -20px;
        }

        .card-top {
            background: $primary;
            width: 90%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            color: white;
            padding: 10px;
            border-radius: 10px;

            .left {
                display: flex;

                img {
                    margin-left: 6px;
                    width: 15px;
                }
            }
        }
    }

    .card-text {
        .card-text-heading {
            padding: 20px 10px 10px 10px;
            background-color: #b5b5b5;
            color: white;
            margin-top: -12px;

            .row {
                align-items: center;
            }

            .name-status {
                display: flex;

                .status {
                    margin-right: 10px;
                }
            }
        }
    }
}

.mz_cards_side_time_left {
    color: white;

    //padding: 10px 15px;
    padding-top: 0px;

    //margin-top: 10px;
    .row {
        width: 100%;
        align-items: center;
        margin: 0;
    }

    .mz_time_left_items {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        .mz_time_left_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 12px;

            .mz_time_left_item_value {
                color: white;

                font-size: 20px;
                font-weight: bold;
            }

            .mz_time_left_item_dots {
                color: white;

                position: absolute;
                top: -6px;
                right: 31px;
                font-size: 25px;
            }
        }
    }
}

.card-body {
    background-color: #e9e9e9;
    color: #838487;
    font-size: 13px;

    .icon {
        padding-left: 5px;
    }

    .card-body-right {
        .b-b {
            border-bottom: 1px solid white;
        }

        .card-body-item {
            padding: 10px;
            display: flex;

            span {
                color: $primary;
            }

            .icon {
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .card-body-left {
        border-right: 1px solid white;
        display: flex;
        align-items: center;
        padding: 10px;

        .icon {
            img {
                width: 20px;
                height: 20px;
            }
        }

        .text {
            span {
                color: $primary;
            }

            div {
                margin-bottom: 5px;
            }
        }
    }
}

.card-footer {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;

    .right {
        img {
            width: 20px;
            margin-left: 8px;
        }
    }

    .left {
        .logo {
            width: 120px;
            margin-left: 10px;
        }

        .arrow {
            width: 25px;
        }
    }
}
</style>
