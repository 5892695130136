<template>
  <div class="home">
    
    <HeroComponent />
    <!-- <SliderComponent /> -->
    <CardsComponent />
<SliderComponent />
    <!-- <MailList /> -->
    
    
  </div>
</template>

<script>

import HeroComponent from '@/components/HeroComponent.vue'
import SliderComponent from '@/components/Home/SliderComponent.vue'
import MailList from '@/components/MailListComponent.vue'
import CardsComponent from '@/components/Home/CardsComponent.vue'

export default {
  name: 'HomeView',
  components: {
    SliderComponent,
    HeroComponent,
    MailList,
    CardsComponent,
  }
}
</script>
