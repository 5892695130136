<template>
  <section class="hero">
    <div class="top-hero">
      <div class="right">
        أخبار جديدة
      </div>
      <div class="left">
        <div class="marquee-container">
          <VTextMarquee
            :speed="50"
            :animate="true"
            class="moving-text"
            :rtl="true"
          >
             
            <h4
              v-for="(item, index) in moved_text"
              :key="index"
              
              class="moved-text"
            >
            
            {{item}}
            <span class="moved-text-img" v-if="!(moved_text.length == index + 1)">
              <img src="@/assets/logoMini.png" alt="" />
            </span>
            </h4>
           
          </VTextMarquee>
        </div>
      </div>
    </div>
    
    <div class="container">
      <div class="img-text">
        <div class="img">
          <img src="@/assets/hero.png" alt="" />
        </div>

        <!-- <div class="text">
          <div class="">
            <span> تغطية </span>
            ... أشمل
          </div>
          
        </div> -->
      </div>
      <!-- <div class="inputs">
        <div class="input-con">

          <input type="text" placeholder="بحث" class="input">
        </div>
        <button type="submit">
          <div class="icon">
            <img src="@/assets/icons/search.png" alt="" />
          </div>
        </button>
      
      </div> -->
    </div>
  </section>
</template>

<script>
import { VTextMarquee } from "vue-text-marquee";

export default {
  components: {
    VTextMarquee
  },
  data(){
    return {
      searchValues: {},
      moved_text: ["تغطية للمزادات ..قريبا", "تغطية للمزادات ..قريبا", "تغطية للمزادات ..قريبا", "تغطية للمزادات ..قريبا", "تغطية للمزادات ..قريبا", "تغطية للمزادات ..قريبا", ]
    }
  }
};
</script>

<style scoped lang="scss">
.top-hero {
  display: flex;
  border-bottom: 2px solid $primary;
  .right {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
background: $primary;
color:white
  }
  .left {
    width:100%
  }
}
.moving-text {
  color: $text;
  background-color: #E9E9E9;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  direction: ltr;

}
.moved-text {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  color: #757575;
  font-weight: normal;
}
.moved-text-img {
  img {
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.hero {
  background: #B5B5B5;
  .img-text {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    .img {
      img {
        width: 500px;
      }
    }
    .text {
      font-size: 60px;
      font-weight: bold;
      span {
        color: $primary;
      }
    }
  }

  .inputs {
    padding-bottom: 60px;
    display: flex;
    width: 80%;
    margin: 50px auto 0px auto;
    .search-form {
      display: flex;

    }
    .input-con {
      width: 100%;
    }
    .input {
      width: 100%;
      height: 100%;
      border: none;
      padding: 5px;
      border-radius:0px 5px 5px 0px;
      &::placeholder {
        color: #969696;
      }
    }
    button {
      background: $primary;
      border: none;
      color: white;
      padding: 4px 8px;
      height: 40px;
      cursor: pointer;
      border-radius:5px 0px 0px 5px;

      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        img {
          width: 20px;
        }
      }
    }
  }
}
</style>
