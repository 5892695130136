<template>
  <div id="app">
    <HeaderComponent v-if="showBar != false"/>
    <!-- <NavBarComponent v-if="showBar != false"/> -->
    <router-view/>
    <FooterComponent v-if="showBar != false"/>
  </div>
</template>
<script>
import HeaderComponent from '@/components/Home/HeaderComponent.vue'
import NavBarComponent from '@/components/NavBarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
  components: {
    HeaderComponent,
    NavBarComponent,
    FooterComponent,

  },
  computed: {
    showBar () {
      return this.$route.meta.showBar
    }
  },
  mounted() {
    console.log(this.$route)
  }
}
</script>

<style lang="scss">
#app {
  direction: rtl;
}
</style>
