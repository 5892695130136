import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
const API_URL = 'https://dashbord.taghtiauctions.sa/api/landapi/';
const clientarea_api = 'https://dashbord.taghtiauctions.sa/api/facility/clientarea/'

const plainAxiosInstance = axios.create({
    baseURL: API_URL,
    //   withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

const clientAreaAxios = axios.create({
    baseURL: clientarea_api,
    //   withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

export { plainAxiosInstance , clientAreaAxios };