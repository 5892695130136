<template>
    <section class="slider">
      <div class="container">
        <VueSlickCarousel v-bind="settings">
          <div class="slider-item">
            <img src="@/assets/1.png" alt="" />

          </div>
          <div class="slider-item">
            <img src="@/assets/2.png" alt="" />

          </div>
          <div class="slider-item">
            <img src="@/assets/3.png" alt="" />

          </div>
          <div class="slider-item">
            <img src="@/assets/4.png" alt="" />

          </div>
          <div class="slider-item">
            <img src="@/assets/5.png" alt="" />

          </div>
          <div class="slider-item">
            <img src="@/assets/6.png" alt="" />

          </div>
        </VueSlickCarousel>
      </div>
    </section>
  </template>
  
  <script>
  import VueSlickCarousel from "vue-slick-carousel";
  import "vue-slick-carousel/dist/vue-slick-carousel.css";
  // optional style for arrows & dots
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
          dots: false,
          arrows: true,
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 1,
          touchThreshold: 5,
          dotsClass: "slick-dots",
          autoplay: true,
        },
      };
    },
  };
  </script>
  
  <style scoped lang="scss">
  .slider {
    background-color: #e9e9e9;
    padding: 20px;
  }
  .slick-dots {
    color: #ccc;
  }
  .slider-item {
    padding: 15px;
    img {
      width: 90%;
      height: 70px;
    }
  }
  </style>
  