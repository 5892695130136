import Vue from 'vue'
import Vuex from 'vuex'

import Auctions from './auctions/index'
import Auth from './auth/index'
import Clientarea from './auctions/clientarea'
import Alert from './alert'


Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    activeCon: "carousel",

  },
  getters: {
    activeCon: (state) => state.activeCon,
  },
  mutations: {
    setActiveCon(state, payload) {
      state.activeCon = payload.newActive
    }
  },
    
    modules: {
      Auctions,
      Clientarea ,
      Auth,
      Alert
    },

})