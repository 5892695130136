import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
    },
  },
  {
    path: "/details/:id",
    name: "details",
    component: function () {
      return import("../views/EstateView.vue");
    },
  },
  {
    path: "/auctions/:id",
    name: "auction",
    component: function () {
      return import("../views/AuctionView.vue");
    },
  },
  {
    path: "/auctions",
    name: "auctions",
    component: function () {
      return import("../views/AuctionsView.vue");
    },
  },
  
  {
    path: "/account",
    name: "account",
    component: function () {
      return import("../views/AccountView.vue");
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: function () {
      return import("../views/CompaniesView.vue");
    },
  },
  {
    
    path: "/company/:id",
    name: "company-profile",
    component: function () {
      return import("../views/CompanyProfileView.vue");
    },
  },
  {
    path: '/terms',
    name: 'Terms of Use',
    component: () => import( '../views/TermsOfUseView.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import( '../views/PrivacyPolicyView.vue')
    
  },
  {
    path: "/login",
    name: "login",
    component: function () {
      return import("../views/LoginView.vue");
    },
  },
  {
    path: "/register/company",
    name: "company_resgister",
    component: function () {
      return import("../views/RegisterCompanyView.vue");
    },
    
  },
  {
    name: "add-auction",
    path: "/add-auction",
    component: function () {
      return import("../views/AddAuctionView.vue");
    },
    children: [
      {
        name: "step1",
        path: "step1",

        component: function () {
          return import("../components/Steps/Step1.vue");
        },
      },
      {
        name: "step2",
        path: "step2",

        component: function () {
          return import("../components/Steps/Step2.vue");
        },
      },
      {
        name: "step3",
        path: "step3",

        component: function () {
          return import("../components/Steps/Step3.vue");
        },
      },
      {
        name: "step4",
        path: "step4",

        component: function () {
          return import("../components/Steps/Step4.vue");
        },
      },
    ]
  },
  {
    path: "/register/person",
    name: "person_register",
    component: function () {
      return import("../views/RegisterPersonView.vue");
    },
  },
  {
    path: "/client",
    name: "client",
    meta: { showBar: false },
    component: function () {
      return import("../views/ClientView.vue");
    },
    children: [
      {
        name: "client-products",
        path: "products",

        component: function () {
          return import("../components/Client/ProductsListComponent.vue");
        },
      },
      {
        path: "auctions",
        name: "auctions",
        component: function () {
          return import("../components/Client/AuctionsListComponent.vue");
        },
      },
      {
        path: "property",
        name: "property",
        component: function () {
          return import("../components/Client/CompanyInfoComponent.vue");
        },
      },

    ],
  },
  {
    path: "/client_area",
    name: "client-area",
    meta: { showBar: false },
    component: function () {
      return import("../views/ClientAreaView.vue");
    },
    children: [
      {
        name: "client-account",
        path: "account",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/AccountComponent.vue");
        },
      },
      {
        name: "client-auctions",
        path: "auctions",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/AuctionsComponent.vue");
        },
      },
      
      {
        name: "client-notifications",
        path: "notifications",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/NotificationsComponent.vue");
        },
      },
      {
        name: "client-settings",
        path: "settings",
        meta: { showBar: false },

        component: function () {
          return import("../components/Client/SettingsComponent.vue");
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
