import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Import the component
import VueSkeletonLoader from 'skeleton-loader-vue';
// Register the component globally
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

import HeaderComponent from './components/HeaderComponent.vue'
import NavBarComponent from './components/NavBarComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

Vue.component('HeaderComponent', HeaderComponent)
Vue.component('NavBarComponent', NavBarComponent)
Vue.component('FooterComponent', FooterComponent)

// Vue Formulate
import VueFormulate from '@braid/vue-formulate'
import { ar } from '@braid/vue-formulate-i18n'
Vue.use(VueFormulate,  {
  plugins: [ ar ],
  locale: 'ar'
})


// Vue unicons
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {uniMapMarkerAlt, uniPhone, uniSignin, uniTwitter, uniLinkedin, uniFacebook, uniYoutube, uniWhatsapp, uniSnapchatGhost, uniSearch, uniBed, uniBath, uniCar, uniVectorSquare, uniPrint, uniHeart, uniShareAlt, uniComparison , uniEye , uniIncomingCall,uniClock, uniRocket, uniCalender, uniApps , uniTimesCircle,uniScenery, uniFile, uniShare, uniAward, uniCheckCircle, uniUserPlus, uniBell, uniSignout, uniLifeRing, uniSetting, uniUser,uniUsersAlt, uniAngleLeftB, uniAngleRightB , uniExclamationTriangle, uniCreditCard, uniKeySkeleton, uniMapMarker, uniBox , uniArrowDown , uniArrowUp, uniTable, uniServerAlt } from 'vue-unicons/dist/icons'

Unicon.add([uniMapMarkerAlt, uniPhone, uniSignin, uniTwitter, uniLinkedin, uniFacebook, uniYoutube, uniWhatsapp, uniSnapchatGhost, uniSearch, uniBed, uniBath, uniCar, uniVectorSquare, uniPrint, uniHeart, uniShareAlt, uniComparison, uniEye , uniIncomingCall, uniClock, uniRocket, uniCalender, uniApps , uniTimesCircle,uniScenery, uniFile, uniShare, uniAward, uniCheckCircle, uniUserPlus, uniBell, uniSignout, uniLifeRing, uniSetting, uniUser,uniUsersAlt, uniAngleLeftB,uniAngleRightB , uniExclamationTriangle, uniCreditCard, uniKeySkeleton, uniMapMarker, uniBox , uniArrowDown , uniArrowUp, uniTable, uniServerAlt ])
Vue.use(Unicon)


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faPhone, faLocationDot)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
