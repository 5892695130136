<template>
  <section class="header">
    <div class="container">
      <div class="header-con">
        <div class="right-side">
          <div class="logo">
            <router-link to="/">

              <img src="@/assets/header_logo.png" alt="" />
            </router-link>
          </div>
          <div class="links">
            <ul>
              <li>
                <router-link to="/"> الرئيسية </router-link>
              </li>
              <li>
                <router-link to="/auctions"> المزادات </router-link>
              </li>
              <li>
                <router-link to="/companies">الشركات </router-link>
              </li>
              <li>
                <router-link to="/call-us"> اتصل بنا </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="left-side">
          <router-link to="/login">
            <button>تسجيل الدخول</button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.header {
  background-color: #f7f7f7;
  padding: 10px;
  .header-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-side {
      display: flex;
      align-items: center;
      .logo {
        img {
          width: 120px;
        }
      }
      .links {
        ul {
          display: flex;
          list-style-type: none;
          padding: 0;
          margin-bottom: 0;
          margin-right: 20px;
          li {
            margin-right: 10px;
            a {
              color: $text;
              font-size: 18px;
            }
            a.router-link-exact-active {
              border-bottom: 1px solid $primary;
              color: $primary;
            }
          }
        }
      }
    }
    .left-side {
      button {
        background-color: $primary;
        color: white;
        padding: 6px 20px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
  }
}
</style>
