// import Vue from 'vue';
import {
    clientAreaAxios
} from '../../axios/index';

const state = {
    firstVisit: true,
    lists: {
        auctions : {} ,
        auction_types : []

    },
    auth_user: JSON.parse(localStorage.getItem("tag-us")),
    loading: false,
    searchValues: { 

    },

    info : {},
   
    addValues: { 
    },

    paginationData: {
        currentPage: undefined,
        lastPage: undefined
    }

}


const getters = {
    addValues : state => state.addValues ,
    loading: state => state.loading,
    firstVisit: state => state.firstVisit,
    lists: state => state.lists,
    info: state => state.info,
    paginationData: state => state.paginationData,
   
}

const actions = {

    async storeData({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)

        return new Promise((resolve, reject) => {
            commit('completeAddValues')
            clientAreaAxios
                .post("asset", state.addValues)
                .then(response => {

                    commit('setLoading', false)
                    if (response.data.status == "success") {
                        resolve(response)
                        commit('resetAddValues')


                        dispatch('Alert/successMsg', {
                            msg: ' تم إضافة العقار بنجاح !! في إنتظار التعميد'
                        }, {
                            root: true
                        })

                    } else {

                        let massage = response.data.title

                        dispatch('Alert/errorMsg', {
                            msg: massage
                        }, {
                            root: true
                        })
                    }
                })
                .catch(error => {
                    reject(error)
                    dispatch('Alert/errorMsg', '', {
                        root: true
                    })
                })

        })
    },

  

    setLoading({
        commit
    }, loading) {
        commit('setLoading', loading)
    },

    async setAuctions({
        commit,
        state
    }, page = 1) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            clientAreaAxios
                .get("index?page=" + page 
                + '&&city_id=' 
                + state.info.city_id 
                + '&&region_id=' 
                + state.info.region_id 
                + '&&type_id=' + state.info.type_id
                + '&&status=' + state.info.status
                )
                .then(response => {
                    state.firstVisit = false

                    // Vue.prototype.$isLoading(false)
                    state.info.searchMode = false
                    state.paginationData.currentPage = response.data.current_page
                    state.paginationData.lastPage = response.data.last_page
                    state.paginationData.total = response.data.total
                    commit('setAuctions', response.data.data)
                   
                    commit('setLoading', false)

                    resolve(response)
                })
                .catch(err => {
                    // Vue.prototype.$isLoading(false)
                    reject(err)
                    commit('setLoading', false)
                    console.log(err.response);
                });
        })


    },


    setAuctionTypes({
        commit,

    }) {

        clientAreaAxios
            .get("auction_type")
            .then(response => {

                commit('setAuctionTypes', response.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },


    setRegions({
        commit
    }) {
        clientAreaAxios
            .get("get_regions")
            .then(response => {

                commit('setRegions', response.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    setCities({
        commit,
    }, region_id) {

        if (region_id != null) {
            clientAreaAxios
                .get("get_cities/" + region_id)
                .then(response => {
                    commit('setCities', response.data);
                })
                .catch(err => {
                    console.log(err.response);
                });
        } else {
            commit('setCities', []);
            commit('setDistricts', []);
        }

    },

   

}
const mutations = {
   
    setAuctionTypes(state, data) {
        state.lists.auction_types = data
    },

    setLoading(state, loading){
        state.loading = loading
    },

    setAuctions(state, data) {
        state.lists.auctions = data
        if (data.length == 0)
            state.info.noDataFound = true
    },
    setRegions(state, data) {
        state.lists.regions = data
    },
    setCities(state, data) {
        state.lists.cities = data
    },

}

export default {

    namespaced: true,
    state,
    getters,
    actions,
    mutations

}