<template>
  <section class="header">
    <div class="container">
      <div class="header-con">
        <div class="row">
          <div class="col-5">
            <div class="header-logo">
              <router-link to="/">

                <img src="@/assets/logo.jpg" alt="" />
              </router-link>
            </div>
          </div>
          <div class="col-5">
            <div class="info-elements">
              <div class="info-element info-element-que">
                <div class="text">
                  <div class="main">أسئلة؟</div>
                  <div class="phone">(+966) 163 831 111</div>
                  <div class="">
                    <a href="mailto:info@aldbaan.sa">info@aldbaan.sa</a>
                  </div>
                </div>
                <div class="icon">
                  <!-- <font-awesome-icon
                    icon="phone"
                    class="header-icon primary-icon"
                  /> -->
                  <unicon name="phone" fill="#F8691B" class="primary-icon" width="45" height="45"></unicon>
                </div>
              </div>
              <div class="info-element info-element-location">
                <div class="text">
                  <div class="main">ساعات العمل</div>
                  <div class="">الأحد - الخميس:</div>
                  <div class="hours">8:00am - 5:00pm</div>
                </div>
                <div class="icon">
                  <!-- <font-awesome-icon
                    icon="location-dot"
                    class="header-icon primary-icon"
                  /> -->
                  <unicon name="map-marker-alt" fill="#F8691B" class="primary-icon" width="45" height="45"></unicon>

                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="header-btn">
              <button>إضافة عقار</button>
            </div>
          </div>
        </div>
        <div class="logo"></div>
      </div>
    </div>
    
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header {
  background-color: #f7f7f7;
  padding: 10px;
  .row {
    align-items: center;
  }
.header-logo {
  img {
    width: 35%;
  }
}
  .info-elements {
    display: flex;
    .info-element {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-left: 1px solid #e0e0e0;
      padding-left: 10px;
      .text {
        color: #aaaaaa;
        font-size: 14px;
        .main {
          color: black;
        }
        .phone,
        .hours {
          direction: ltr;
        }
      }
      .icon {
        margin-right: 10px;
        
        .primary-icon {
          height: 45px;
          width: 45px;
        }
      }
    }
  }
  .header-btn {
    button {
      background-color: #E9E9E9;
      border: none;
      padding: 8px 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }
}
</style>
