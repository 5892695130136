<template>
  <Footer>
    <!-- <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-3 first">
            <span> منصة تغطية للمزادات </span>
            <span> N9.0.38 9/10/2023 </span>
            <span> الرقم الضريبي: 300710482300003 </span>
          </div>
          <div class="col-6 second">
            <img src="@/assets/icons/location.png" alt="" />
            <span>
              المملكة العربية السعودية تقاطع الملك سلمان مع طريق الامام البخاري
            </span>
          </div>
          <div class="col-3 third">
            <div class="social">
              <img src="@/assets/icons/twitter.png" alt="" />
              <img src="@/assets/icons/instagram.png" alt="" />
              <img src="@/assets/icons/twitter.png" alt="" />
              <img src="@/assets/icons/instagram.png" alt="" />
            </div>
            <div class="call-us">
              <span>0163831111 - </span>
              <span>0163850000</span>
              <img src="@/assets/icons/call.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container">
      <div class="footer-bottom">
        <div class="right">
          <router-link to="/terms">
            شروط الإستخدام وإخلاء المسؤولية
          </router-link>
          |
          <router-link to="/privacy">
            سياسة الخصوصية وسرية المعلومات
          </router-link>
        </div>
        <div class="left">
          <div class="text">جميع الحقوق محفوظة لمؤسسة تغطية </div>
          <div class="img">
            <img src="@/assets/header_logo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </Footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
footer {
  margin-top: 30px;
  .footer-top {
    padding: 10px;
    background-color: #818285;
    color: white;
    font-size: 14px;
    .first {
      span {
        display: block;
        text-align: center;
      }
    }
    .second {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        margin-left:5px;
      }
    }

    .third {
      display: flex;
      flex-direction: column;
      align-items: center;
      .social {
        display: flex;

        img {
          width: 30px;
          margin-left: 15px;
        }
      }
      .call-us {
        margin-top: 20px;
        img {
          width: 30px;
          margin-right: 10px;
        }
      }
    }
  }
  .footer-bottom {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: $text;
    a {
      color: $text;
      &:hover {
        color: $primary;
      }
    }
    .right {
    }
    .left {
      display: flex;
      img {
        width: 140px;
        margin-right: 15px;
      }
    }
  }
}
</style>
